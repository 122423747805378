<template>
  <span class="d-flex">
    <v-select
      v-model="mainCol"
      class="pt-0 mb-0"
      hide-details
      :items="cols"
      item-text="title"
      item-value="id"
      label="Основная колонка"
    />
    <v-autocomplete
      v-model="filterRows"
      class="pt-0 mb-0"
      hide-details
      :disabled="!mainCol"
      :items="rows"
      item-text="title"
      item-value="id"
      multiple
      label="Фильтровать строки"
    >
      <template #prepend-item>
        <v-list-item
          ripple
          @mousedown.prevent
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon :color="filterRows && filterRows.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Выбрать все
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip
          v-if="index === 0"
          x-small
        >
          <span>{{ item.title.substring(0, 6) }}{{ item.title.length > 6 ? '...' : '' }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="grey--text text-caption"
        >
          (+{{ filterRows.length - 1 }} других)
        </span>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="filterCols"
      class="pt-0 mb-0"
      hide-details
      :items="allcols"
      item-text="title"
      item-value="id"
      multiple
      label="Фильтровать колонки"
      :search-input.sync="searchText"
    >
      <template #prepend-item>
        <v-list-item
          ripple
          @mousedown.prevent
          @click="toggleCols"
        >
          <v-list-item-action>
            <v-icon :color="filterCols && filterCols.length > 0 ? 'indigo darken-4' : ''">
              {{ iconCols }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="!searchFilterActive">Выбрать все</span>
              <span v-else>Выбрать найденные</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip
          v-if="index === 0"
          x-small
        >
          <span>{{ item.title.substring(0, 6) }}{{ item.title.length > 6 ? '...' : '' }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="grey--text text-caption"
        >
          (+{{ filterCols.length - 1 }} других)
        </span>
      </template>
    </v-autocomplete>
    <v-btn
      v-if="(filterRows && filterRows.length > 0) || (filterCols && filterCols.length > 0)"
      icon
      dark
      :color="'blue-grey lighten-3'"
      @click="clearFilter"
    >
      <v-icon>mdi-filter-remove-outline</v-icon>
    </v-btn>
  </span>
</template>

<script>
  export default {
    name: 'RowFilter',
    props: {
      table: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        searchFilterActive: false,
        searchText: '',
      }
    },
    computed: {
      allcols () {
        return this.table.col
      },
      likesAllRows () {
        if (this.filterRows && this.rows) {
          return this.filterRows.length === this.rows.length
        } else {
          return false
        }
      },
      likesSomeRows () {
        if (this.filterRows) {
          return this.filterRows.length > 0 && !this.likesAllRows
        } else {
          return false
        }
      },
      likesAllCols () {
        if (this.filterCols) {
          return this.filterCols.length === this.allcols.length
        } else {
          return false
        }
      },
      likesSomeCols () {
        if (this.filterCols) {
          return this.filterCols.length > 0 && !this.likesAllCols
        } else {
          return false
        }
      },
      icon () {
        if (this.likesAllRows) return 'mdi-close-box'
        if (this.likesSomeRows) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      iconCols () {
        if (this.likesAllCols) return 'mdi-close-box'
        if (this.likesSomeCols) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      rows () {
        if (this.mainCol) {
          const rows = []
          this.table.data.forEach((r) => {
            rows.push({ id: r[this.mainColId].pldrowid, title: r[this.mainColId].main_value })
          })
          // const frows = this.table.data.filter()
          return rows
        } else {
          return []
        }
      },
      mainColId () {
        return this.table.col.find(x => x.plcolid === this.mainCol).id
      },
      cols () {
        const fcol = this.table.col.filter(x => x.coltype < 0)
        const cols = []
        fcol.forEach((x) => {
          if (!x.title) {
            cols.push({ title: '_' + x.plcolid, id: x.plcolid })
          } else {
            cols.push({ title: x.title, id: x.plcolid })
          }
        })
        return cols
      },
      mainCol: {
        get () {
          return this.$store.getters.tableFilters.mainCol[this.table.info.pltmpltableid]
        },
        set (newVal) {
          const payload = {
            pltmpltableid: this.table.info.pltmpltableid,
            mainCol: newVal,
          }
          this.$store.commit('setTableFilterMainCol', payload)
        },
      },
      filterCols: {
        get () {
          return this.$store.getters.tableFilters.cols[this.table.info.id]
        },
        set (newVal) {
          const payload = {
            pltmpltableid: this.table.info.id,
            cols: newVal,
          }
          this.$store.commit('setTableFilterCols', payload)
        },
      },
      filterRows: {
        get () {
          return this.$store.getters.tableFilters.rows[this.table.info.pltmpltableid]
        },
        set (newVal) {
          const payload = {
            pltmpltableid: this.table.info.pltmpltableid,
            rows: newVal,
          }
          this.$store.commit('setTableFilterRows', payload)
        },
      },
    },
    methods: {
      toggle () {
        this.$nextTick(() => {
          if (this.likesAllRows) {
            this.filterRows = null
          } else {
            const tmp = []
            this.rows.forEach((v) => {
              tmp.push(v.id)
            })
            this.filterRows = tmp.slice()
          }
        })
      },
      toggleCols () {
        if (!this.searchFilterActive) {
          this.$nextTick(() => {
            if (this.likesAllCols) {
              this.filterCols = null
            } else {
              this.filterCols = this.allcols.map(v => v.id)
            }
          })
        } else {
          const filteredItems = this.allcols.filter(item =>
            item.title?.toLowerCase().includes(this.searchText?.toLowerCase())
          )
          if (this.filterCols?.length === filteredItems.length) {
            this.filterCols = null
          } else {
            this.filterCols = filteredItems.map(v => v.id)
          }
        }
      },
    },
    watch: {
      filterCols: {
        handler () {
          this.$emit('changeFilterCheckbox')
        },
      },
      searchText () {
        this.searchText ? this.searchFilterActive = true : this.searchFilterActive = false
      },
    },
  }
</script>
